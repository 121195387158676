import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

const Loader = () => {
    const theme = useTheme()
    return (
        <Box height={40} my={2} display="flex" alignItems="center" justifyContent="center">
            {Array.from(Array(3)).map((_, i) =>
                <Box sx={{ animationDelay: `${(i + 1) * 0.05}s !important` }} key={i} className="spinner-grow spinner-grow-sm mx-1" color={theme.palette.primary.main} role="status" />
            )}
        </Box>
    );
}

export default Loader;