import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_PLP_PRODUCTS } from "../../graphql/products/queries";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import parseSearchParams, { strEncode } from "../../helpers/SearchParams";
import { parseFilters } from "../../helpers/parseFilters";

const useLazyProducts = (data, catData, forSearch) => {
    const router = useRouter()
    const searchParams = parseSearchParams(router)
    const [skip, setSkip] = useState(true)
    const [curr, setCurr] = useState(data.products.page_info.current_page)
    const { data: prevData, loading: prevLoading } = useQuery(GET_PLP_PRODUCTS, {
        variables: {
            pageSize: data.products.page_info.page_size,
            currentPage: curr - 1,
            filters: parseFilters((searchParams.filters || {}), catData ? catData.categories.items.at(0).uid : -1),
            sort: (searchParams.sort || (forSearch ? { "relevance": "DESC" } : { "position": "ASC" })),
        },
        skip
    })

    const [nextLoading, setNextLoading] = useState(false)

    const firstUrl = forSearch ? router.asPath : `${router.asPath.endsWith('.html') ? router.asPath : router.asPath.split('/').slice(0, -1).join('/')}`

    const [products, setProducts] = useState([])
    // for dev only disable before build
    // let initialized = false
    useEffect(() => {
        // for dev only disable before build (if part)
        // if (!initialized) {
        //     initialized = true
        setProducts((products) => [...products, ...data.products.items])
        // }
    }, [data.products.items]);

    useEffect(() => {
        const handleSameAgain = (url, { shallow }) => {
            if (!shallow && firstUrl === url) {
                setProducts([])
            }
        }

        router.events.on("routeChangeStart", handleSameAgain)

        return () => {
            router.events.off("routeChangeStart", handleSameAgain)
        }
    }, [])

    const getLink = (page) => {
        if (page >= 1 && page <= data.products.page_info.total_pages) {
            searchParams.page = page
            const url = `${router.asPath.endsWith('.html') ? router.asPath : router.asPath.split('/').slice(0, -1).join('/')}/${strEncode(JSON.stringify(searchParams))}`
            return url
        }
    }

    const previous = () => {
        setSkip(false)
    }

    useEffect(() => {
        if (prevData) {
            setSkip(true)
            setProducts((products) => [...prevData.products.items, ...products])
            setCurr(curr => curr - 1)
        }
    }, [prevData])

    const next = () => {
        setNextLoading(true)
        router.push(getLink(data.products.page_info.current_page + 1), undefined, { scroll: false }).then(() => { setNextLoading(false) })
    }

    const showPrev = curr > 1 && products.length < data.products.total_count && products.length < data.products.page_info.current_page * data.products.page_info.page_size && !nextLoading && products.length > 0
    const showNext = data.products.page_info.current_page < data.products.page_info.total_pages && products.length > 0

    return ({ products, previous, next, prevLoading, nextLoading, showPrev, showNext, curr });
}

export default useLazyProducts;