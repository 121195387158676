import { Box, Button } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import cssVars from "../../styles/vars.module.scss"
import { memo } from "react";
import Loader from "./loader";

const LazyLoad = ({ previous, next, prevLoading, nextLoading, showPrev, showNext, children }) => {

    const { t } = useTranslation()
    const theme = useTheme()

    return (
        <Box>
            {
                showPrev
                    ?
                    prevLoading
                        ?
                        <Loader />
                        :
                        <Button onClick={previous} sx={{ my: 2, mx: "auto", p: 0, color: theme.palette.primary.main, bgcolor: `${cssVars.white} !important`, display: "block" }}>
                            {t("Load previous")}
                        </Button>
                    :
                    null
            }
            {children}
            {
                showNext
                    ?
                    nextLoading
                        ?
                        <Loader />
                        :
                        <Button onClick={next} sx={{ my: 2, mx: "auto", p: 0, color: theme.palette.primary.main, bgcolor: `${cssVars.white} !important`, display: "block" }}>
                            {t("Load more")}
                        </Button>
                    :
                    null
            }

        </Box>
    );
}

export default memo(LazyLoad);