import { memo } from "react";
import PaginatedProductList from "../paginated-product-list";
import LazyProductList from "../lazy-product-list";

const ProductCollection = ({ data, catData, storeConfig, forSearch, campaign, isCalibration }) => {
    const isInfiniteScoll = forSearch
        ?
        storeConfig.search_infinite_scroll === "enable"
        :
        (catData?.categories.items[0].infinite_scroll && catData?.categories.items[0].infinite_scroll !== "use_system_config")
            ?
            catData?.categories.items[0].infinite_scroll === "enable"
            :
            storeConfig.infinite_scroll === "enable"

    return (
        isInfiniteScoll
            ?
            <LazyProductList data={data} catData={catData} forSearch={forSearch} />
            :
            <PaginatedProductList data={data} catData={catData} campaign={campaign} isCalibration={isCalibration}/>
    );
}

export default memo(ProductCollection);