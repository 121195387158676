import { Box, Breadcrumbs } from "@mui/material";
import Link from "next/link";
import cssVars from "../../styles/vars.module.scss"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTheme } from "@mui/material";
import { getIcon } from "../../helpers/getIcon";

const BreadCrumb = ({ breadcrumbs, activeName }) => {
    let brs = [...(breadcrumbs || [])]
    brs = brs.sort((a, b) => a.category_level - b.category_level)
    const theme = useTheme()

    return (
        breadcrumbs && activeName
            ?
            <Box component="nav" sx={{ "& .MuiBreadcrumbs-separator": { mx: "4px", "& svg": { fontSize: "2rem" } } }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                    sx={{ "& ol": { display: "flex", width: "100%", flexWrap: "nowrap", overflow: "auto", alignItems: "center", pb: 1 }, "& ol li": { minWidth: "fit-content" } }}
                >
                    {
                        brs.map((breadcrumb) =>
                            <Box fontSize="14px" fontWeight={500} key={breadcrumb.category_name} sx={{ minWidth: "fit-content", ":hover": { color: theme.palette.primary.main }, color: cssVars.extraDarkGray }}>
                                <Link href={breadcrumb.category_url_path + '.html'} aria-label={breadcrumb.category_name + " breadcrumb link"}>{breadcrumb?.category_level > 2 ? breadcrumb.category_name : getIcon(breadcrumb.category_name, 22)}</Link>
                            </Box>
                        )
                    }
                    <Box fontSize="14px" fontWeight={500} component="span" sx={{ color: cssVars.extraDarkGray }}>{activeName}</Box>
                </Breadcrumbs>
            </Box>
            :
            null
    );
}

export default BreadCrumb;