import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import { useState } from "react";
import MenuCard from "../header/MenuCard";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import cssVars from "../../styles/vars.module.scss";
import useTranslation from "../../hooks/useTranslation";

const PLPSubCategories = ({ catData }) => {
    const [subCatsExpanded, setSubCatsExpanded] = useState(false)
    const { t } = useTranslation()

    return (
        catData?.categories?.items?.at(0)?.children?.length > 0
            ?
            <Box
                mt={1} mb={3}
                sx={{ "& .MuiPaper-root": { bgcolor: "#fff", border: "none !important", p: 0, m: "0px !important", "& .MuiButtonBase-root": { minHeight: "fit-content" }, "& .MuiAccordionSummary-content": { m: 0 } } }}
            >
                <Box display={{ xs: "none", lg: "block" }} borderTop={`0.5px solid ${cssVars.lightGray}`}>
                    <Box display="flex" flexWrap="wrap">
                        {
                            catData.categories.items[0].children.map((subCat, i) =>
                                <MenuCard key={subCat.uid + "-0"} link={subCat.url_path + ".html"} title={subCat.name} img={subCat.thumbnail ? process.env.NEXT_PUBLIC_BASE_URL + subCat.thumbnail : null} count={subCat.product_count} className={(((i + 1) % 9) === 0) ? "border-end-0" : ""} />
                            )
                        }
                    </Box>
                </Box>
                <Accordion expanded={subCatsExpanded} onChange={() => { setSubCatsExpanded(expanded => !expanded) }} sx={{ display: catData?.categories.items[0].children.length > 0 ? { lg: "none" } : "none", mt: 2 }}>
                    <AccordionSummary>
                        <Box display="flex" alignItems="center" justifyContent="space-between" p="6px" width="100%" bgcolor={cssVars.extraLightGray} sx={{ borderRadius: `${cssVars.radius} ${cssVars.radius} 0 0` }}>
                            <Box>
                                {t('Sub-Categories')}
                            </Box>
                            <Box sx={{ rotate: (subCatsExpanded ? "90deg" : "270deg"), transition: "rotate 0.3s linear" }}>
                                <ChevronLeftIcon sx={{ fontSize: "34px" }} />
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ display: "flex", flexWrap: "wrap", borderTop: `0.5px solid ${cssVars.lightGray}` }}>
                            {

                                catData.categories.items[0].children.map((subCat, i) =>
                                    <MenuCard key={subCat.uid + "-1"} link={subCat.url_path + ".html"} title={subCat.name} img={subCat.thumbnail ? process.env.NEXT_PUBLIC_BASE_URL + subCat.thumbnail : null} count={subCat.product_count} className={(((i + 1) % 2) === 0) ? " border-end-0" : ""} />
                                )
                            }
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
            :
            null
    );
}

export default PLPSubCategories;