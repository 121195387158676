import { useEffect, useRef, useState } from "react";
import styles from './style.module.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Sorticon from '../../media/icons/sort.svg';
import { useRouter } from "next/router"
import parseSearchParams, { strEncode } from "../../helpers/SearchParams";
import { Box, Button, List, ListItem, Menu, MenuItem } from "@mui/material";
import Drawer from "../drawer/drawer";
import useTranslation from "../../hooks/useTranslation";
import Link from "next/link";
import cssVars from "../../styles/vars.module.scss";

const CustomSelect = ({ sortData, forSearch }) => {
    const id = "plp-sort"
    const router = useRouter()
    const searchParams = parseSearchParams(router)
    const { t } = useTranslation()
    const options = forSearch ? sortData.products.sort_fields.options.map(op => op.value === "position" ? { ...op, label: t("Best match"), value: "relevance" } : op) : sortData.products.sort_fields.options;
    const defValue = options.filter(op => op.value === Object.keys((searchParams?.sort || {}))?.at(0))?.at(0)?.value || forSearch ? "relevance" : sortData.products.sort_fields.default
    const selValue = (Object.keys((searchParams?.sort || {}))?.at(0) || defValue)
    const buttonText = options.filter(option => option.value === selValue)?.at(0)?.label
    const [showDrawer, setShowDrawer] = useState(false)
    const svg1 = useRef()
    const svg2 = useRef()

    useEffect(() => {
        if (showDrawer) {
            svg1.current.classList.add(styles['rotated-icon'])
            svg2.current.classList.add(styles['rotated-icon'])
        }
        else {
            svg1.current.classList.remove(styles['rotated-icon'])
            svg2.current.classList.remove(styles['rotated-icon'])
        }

    }, [showDrawer])

    const handleSelect = (label, value) => {
        let s = searchParams || {}
        delete s.page
        s.sort = { [value]: ((value === "position") ? "ASC" : "DESC") }
        s = Object.fromEntries(Object.entries(s).sort())
        svg1.current.classList.toggle(styles['rotated-icon'])
        setShowDrawer(false)
        let localParam = "/" + strEncode(JSON.stringify(s))
        if (Object.keys(parseSearchParams(router)).length === 0)
            router.push(router.asPath + localParam)
        else
            router.push(router.asPath.split('/').slice(0, -1).join('/') + localParam)
    }

    const open = (setShowfunc, show) => {
        svg1.current.classList.toggle(styles['rotated-icon'])
        setShowfunc(show)
    }

    const dropDownMenuRef = useRef()

    useEffect(() => {
        window.addEventListener('click', function (e) {
            if (document.querySelector('.dropdown-sort #dropdown' + id + ".show") && !document.querySelector('.dropdown-sort #dropdown' + id + ".show").parentElement.contains(e.target)) {
                console.log("xxx");
                svg2.current.classList.toggle(styles['rotated-icon'])
                dropDownMenuRef.current.classList.remove("show")
            }
        });
        return () => { }
    }, [])

    return (
        <>
            <Box className="dropdown-sort" position="relative" sx={{ display: { xs: "none", lg: "block" } }}>
                <Button sx={{ display: { xs: "none", md: "flex" }, mr: 1.5 }} className={"label " + styles.label} onClick={() => {
                    svg2.current.classList.toggle(styles['rotated-icon'])
                    dropDownMenuRef.current.classList.toggle("show")
                }} >
                    <Sorticon /> <Box component="span">{buttonText}</Box> <KeyboardArrowDownIcon ref={svg2} className="" fontSize="medium" />
                </Button>

                <Box
                    ref={dropDownMenuRef}
                    className={"dropdown-menu " + styles.dropdown}
                    id={'dropdown' + id}
                    sx={{ display: "none", position: "absolute", top: "-10px", "&.show": { display: "block" } }}
                >
                    <List className={styles['sort-list']}>
                        {options.map(option =>
                            <ListItem sx={{}} key={`${option.label}_${option.value}`} className={buttonText === option.label ? styles.active : ""} onClick={() => { handleSelect(option.label, option.value) }}>
                                {option.label}
                            </ListItem>
                        )}
                    </List>
                </Box>
            </Box>
            <Button sx={{ display: { xs: "flex", md: "none !important" }, width: "100%", justifyContent: "space-between" }} className={"label " + styles.label} onClick={(e) => { open(setShowDrawer, !showDrawer) }}>
                <Box component="span"><Sorticon /> <Box component="span">{buttonText}</Box></Box> <KeyboardArrowDownIcon ref={svg1} className="" fontSize="medium" />
            </Button>
            <Drawer open={showDrawer} setOpen={setShowDrawer} swipeable={false} anchor="bottom" >
                {
                    options.map(option =>
                        <MenuItem sx={(theme) => ({ p: 0, "&>a": { p: "6px 16px", color: (buttonText === option.label ? theme.palette.primary.main : cssVars.black) } })} key={option.label + "_" + option.value} onClick={() => { setOpenSortingDrawer(false) }} >
                            <Link onClick={e => { e.preventDefault(); handleSelect(option.label, option.value) }} href="#" style={{ display: "block", width: "100%" }}>{option.label}</Link>
                        </MenuItem>
                    )
                }
            </Drawer>
        </>
    );
}

export default CustomSelect;