import { memo } from "react";
import LazyLoad from "../lazy-load";
import ProductList from "../product-list/productList";
import useLazyProducts from "./useLazyProducts";

const LazyProductList = ({ data, catData, forSearch }) => {

    const { products, previous, next, prevLoading, nextLoading, showPrev, showNext, curr } = useLazyProducts(data, catData, forSearch)

    return (
        <LazyLoad
            showPrev={showPrev} showNext={showNext}
            previous={previous} next={next}
            prevLoading={prevLoading} nextLoading={nextLoading}
        >
            <ProductList products={products.length > 0 ? products : data.products.items} category_path={catData?.categories.items[0].url_path} pageSize={data.products.page_info.page_size} current_page={curr}/>
        </LazyLoad>
    );
}

export default memo(LazyProductList);