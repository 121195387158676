import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import { useState } from "react";
import ProductCategoryDownloads from "../product-category-downloads";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import cssVars from "../../styles/vars.module.scss";
import useTranslation from "../../hooks/useTranslation";

const PLPFiles = ({ files }) => {
    const [filesExpanded, setFilesExpanded] = useState(false)
    const { t } = useTranslation()

    return (
        files && files.length > 0
            ?
            <Box sx={{ "& .MuiPaper-root": { bgcolor: "#fff", border: "none !important", p: 0, m: "0px !important", "& .MuiButtonBase-root": { minHeight: "fit-content" }, "& .MuiAccordionSummary-content": { m: 0 } } }}>
                <Box display={{ xs: "none", lg: "block" }} >
                    <ProductCategoryDownloads variant="row" files={files} />
                </Box>
                <Accordion expanded={filesExpanded} onChange={() => { setFilesExpanded(expanded => !expanded) }} sx={{ display: { lg: "none" }, mt: 2 }}>
                    <AccordionSummary className="shadow-sm">
                        <Box display="flex" alignItems="center" justifyContent="space-between" p="6px" width="100%" bgcolor={cssVars.extraLightGray} sx={{ borderRadius: `${cssVars.radius} ${cssVars.radius} 0 0` }}>
                            <Box>
                                {t('Category Downloads')}
                            </Box>
                            <Box sx={{ rotate: (filesExpanded ? "90deg" : "270deg"), transition: "rotate 0.3s linear" }}>
                                <ChevronLeftIcon sx={{ fontSize: "34px" }} />
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ProductCategoryDownloads variant="col" files={files} />
                    </AccordionDetails>
                </Accordion>
            </Box>
            :
            null
    );
}

export default PLPFiles;