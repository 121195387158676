import { Box, Button } from "@mui/material";
import DropdownFilter from "../dropdown-filter";
import CustomSelect from "../custom-select/customSelect";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import useTranslation from "../../hooks/useTranslation";
import cssVars from "../../styles/vars.module.scss";
import FiltersDrawer from "./filtersDrawer";
import { useState } from "react";
import CalibrationFilters from "./calibrationFilters";

const PLPFiltersSort = ({ filtersData, sortData, forSearch, isCalibration }) => {
    const excludeFilters = ["has_low_price", "clearance", "on_sale", "category_uid"];
    const filters = isCalibration ? null : filtersData.products.aggregations.filter((filter) => !excludeFilters.includes(filter.attribute_code));
    const { t } = useTranslation()
    const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false)

    return (
        <Box component="section">
            <Box display={{ lg: "flex" }} alignItems="center">

                {
                    isCalibration
                        ?
                        <CalibrationFilters filtersData={filtersData} />
                        :
                        <>
                            <CustomSelect sortData={sortData} forSearch={forSearch} />
                            <Box display={{ xs: "none", lg: "flex" }}>
                                {
                                    filters.map((filter) => {
                                        return (
                                            <Box key={filter.attribute_code}>
                                                <DropdownFilter filter={filter} />
                                            </Box>
                                        );
                                    })
                                }
                            </Box>
                            <Box display={{ xs: "block", lg: "none", }} mb={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ borderRadius: '25px', display: "flex", justifyContent: "space-between", bgcolor: `${cssVars.green} !important`, color: "#fff", px: "20px" }}
                                    onClick={() => { setOpenFiltersDrawer(true) }}
                                >
                                    {t('Filter')} <FilterAltOutlinedIcon fontSize="medium" sx={{ mr: "-4px" }} />
                                </Button>
                                <FiltersDrawer filters={filters} openFiltersDrawer={openFiltersDrawer} setOpenFiltersDrawer={setOpenFiltersDrawer} />
                            </Box>
                        </>
                }
            </Box>
        </Box>
    );
}

export default PLPFiltersSort;