import { Autocomplete, Box, selectClasses, TextField } from "@mui/material";
import cssVars from "../../styles/vars.module.scss";
import useTranslation from "../../hooks/useTranslation";
const CalibrationFilter = ({ filter: { title, code, options }, selectedFilters, setSelectedFilters, formCheck }) => {
    const { t } = useTranslation()
    console.log(selectedFilters);
    
    return (
        <Box sx={{ mr: 1.5, mb: 2.5, minWidth: {xs:"100%",md:240} }} position="relative">
            <Autocomplete
                sx={{ "& button.Mui-disabled": { bgcolor: "#fff !important" } }}
                options={options}
                disabled={options.length === 0}
                value={(selectedFilters[code] || null)}
                onChange={(_, value) => {
                    setSelectedFilters(code === "brand" ? { [code]: value } : { ...selectedFilters, [code]: value })
                }}
                renderInput={(params) => (
                    <TextField {...params} label={title} variant="outlined" size="small" />
                )}

            />
            <Box position="absolute" fontSize={12} color={cssVars.red} display={formCheck && !selectedFilters[code] && options.length > 0 ? "block" : "none"}>{title} {t("field is required")}</Box>
        </Box>
    );
}

export default CalibrationFilter;