export const parseFilters = (filters, id = -1) => {
    const rtn = id !== -1 ? { category_id: { in: [id] } } : {}

    for (const [filter, filterValue] of Object.entries(filters)) {
        if (filter === 'price') {
            rtn[filter] = { from: filterValue[0], to: filterValue[1] }
        }
        else if (filter === 'category_uid') {
            rtn["category_id"] = { in: filterValue }
        }
        else {
            rtn[filter] = { in: filterValue }
        }
    }
    return rtn
}