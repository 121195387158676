import { useRouter } from "next/router";
import parseSearchParams, { strEncode } from "../../helpers/SearchParams";
import CalibrationFilter from "./calibrationFilter";
import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import cssVars from "../../styles/vars.module.scss";
import { useCallback } from "react";
import { useGlobalStore } from "../../stores/globalStore";

const CalibrationFilters = ({ filtersData }) => {
    const router = useRouter()
    const searchParams = parseSearchParams(router)
    const initialFilters = searchParams?.filters || {}
    const brands = filtersData.calibrationBrands.map(item => item.brand)
    const [selectedFilters, setSelectedFilters] = useState(initialFilters)
    useEffect(() => {
        setSelectedFilters(initialFilters)
    }, [])
    

    const models = filtersData.calibrationBrands.find(item => item.brand === selectedFilters?.brand)?.models || []
    const [formCheck, setFormCheck] = useState(false)

    const { t } = useTranslation()
    const filters = [
        {
            title: t("Brand"),
            code: "brand",
            options: brands.sort((a, b) => a.localeCompare(b))
        },
        {
            title: t("Model"),
            code: "model",
            options: models.sort((a, b) => a.localeCompare(b))
        }
    ]

    const doFilter = useCallback(() => {

        if (Object.keys(selectedFilters).length === 2 && Object.values(selectedFilters).every(value => Boolean(value))) {
            let s = parseSearchParams(router)
            delete s.page
            if (!s.filters) {
                s.filters = {}
            }

            Object.entries(selectedFilters).forEach(([attribute_code, option]) => {
                s.filters[attribute_code] = option
            })

            s.filters = Object.fromEntries(Object.entries(s.filters).sort())
            s = Object.fromEntries(Object.entries(s).sort())

            if (Object.keys(s).length === 0) {
                router.push(`${router.asPath.endsWith('.html') ? router.asPath : router.asPath.split('/').slice(0, -1).join('/')}`)
            }
            else {
                router.push(`${router.asPath.endsWith('.html') ? router.asPath : router.asPath.split('/').slice(0, -1).join('/')}/${strEncode(JSON.stringify(s))}`)
            }
        }
        else {
            setFormCheck(true)
        }
    }, [selectedFilters, router])

    const clearFilters = () => {
        if (Object.keys(parseSearchParams(router)).length > 0)
            router.push(router.asPath.split('/').slice(0, -1).join('/'))
    }

    return (
        <Box id="calibration-filters" display={{ md: "flex" }} alignItems="center" mt={{ xs: 2, md: 0 }} mb={{ xs: 5, md: 0 }}>
            {
                filters?.map(filter =>
                    <CalibrationFilter key={filter.code} filter={filter} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} formCheck={formCheck} />
                )
            }
            <Button onClick={doFilter} sx={{ borderRadius: cssVars.radius, bgcolor: cssVars.blue, color: "#fff", "&:hover": { bgcolor: cssVars.darkBlue }, mr: 1.5, mb: 2.5, px: 2, width: { xs: "100%", md: "fit-content" } }}>{t("Apply")}</Button>
            {
                Object.keys(initialFilters).length > 0
                    ?
                    <Box mx={{ xs: "auto", md: "0" }} width="fit-content" fontSize={14} color={cssVars.extraDarkGray} fontWeight={500} sx={{ textDecoration: "underline", mr: 1.5, mb: 2.5, cursor: "pointer" }} onClick={clearFilters}>{t("Reset selections")} </Box>
                    :
                    null
            }
        </Box>

    );
}

export default CalibrationFilters;