import MenuBookIcon from '@mui/icons-material/MenuBook';
import InterestsIcon from '@mui/icons-material/Interests';
import HandshakeIcon from '@mui/icons-material/Handshake';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

export const getIcon = (name, size, color) => {
    console.log(name);
    const map = {
        'Assortiment': <MenuBookIcon style={{ fontSize: size }} className="blue" />,
        'Consommables': <InterestsIcon style={{ fontSize: size }} className="green" />,
        'Services': <HandshakeIcon style={{ fontSize: size }} className="yellow" />,
        'Nouveautes': <RocketLaunchIcon style={{ fontSize: size }} className="lightBlue" />,
        'Actions': <WhatshotIcon style={{ fontSize: size }} className="red" />,
        'Marques': <LocalOfferIcon style={{ fontSize: size }} className="extraDarkGray" />,
    }
    
    return map[name]
}