import { Box, Grid, List, ListItem } from "@mui/material"
import Link from "next/link"
import { useRouter } from "next/router"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import parseSearchParams, { strEncode } from "../../helpers/SearchParams";
import { useTheme } from "@mui/material";

const Pagination = ({ pageCount, btnCount = 5, currPage }) => {
    const router = useRouter()
    const searchParams = parseSearchParams(router)
    const theme = useTheme()

    if (pageCount <= 1) {
        return (<Grid></Grid>)
    }


    const getLink = (page) => {
        if (page >= 1 && page <= pageCount) {
            searchParams.page = page
            const url = `${router.asPath.endsWith('.html') ? router.asPath : router.asPath.split('/').slice(0, -1).join('/')}/${strEncode(JSON.stringify(searchParams))}`
            return url
        }
    }

    const generateButtons = () => {
        let btns = new Set()
        let start = currPage - Math.ceil((btnCount - 1) / 2)
        if (start < 1) start = 1
        let end = start + btnCount - 1;
        if (end > pageCount) {
            end = pageCount
            if ((end - btnCount) + 1 > 1) {
                start = (end - btnCount) + 1
            }
            else {
                start = 1
            }
        }

        for (let index = start; index <= end; index++) {
            let item = <ListItem disablePadding>
                <Link href={getLink(index)} className={currPage === index ? "active" : ""}>{index}</Link>
            </ListItem>
            btns.add(item)
        }
        return btns
    }
    console.log(pageCount);
    
    const paginationStyle = {
        color: theme.palette.primary.main, border: `1px solid ${theme.palette.primary.main} !important`,
        "&.active": {
            backgroundColor: `${theme.palette.primary.main} !important`, color: `#fff !important`
        },
        "&:hover:not(.disabled)": {
            backgroundColor: `${theme.palette.primary.dark} !important`,
            color: `#fff !important`,
            "& svg": {
                fill: `#fff !important`,
            }
        },
        "& svg": {
            fill: `${theme.palette.primary.main} !important`,
        }
    }

    return (
        <Grid className="pagination roboto" sx={{ "& ul li>*": paginationStyle, justifyContent: { xs: "center", sm: "end" } }}>
            <List disablePadding>
                <ListItem key={"prev"} disablePadding>
                    {
                        getLink(currPage - 1)
                            ?
                            <Link href={getLink(currPage - 1)} aria-label="pagination previous page link" className={(currPage === 1 ? 'arrow disabled' : 'arrow')}>
                                <ArrowBackIosNewIcon />
                            </Link>
                            :
                            <Box className={(currPage === 1 ? 'arrow disabled ' : 'arrow')}>
                                <ArrowBackIosNewIcon />
                            </Box>
                    }
                </ListItem>
                {
                    generateButtons()
                }
                <ListItem key={"next"} disablePadding>

                    {
                        getLink(currPage + 1)
                            ?
                            <Link href={getLink(currPage + 1)} aria-label="pagination next page link" className={(currPage === pageCount ? 'arrow disabled' : 'arrow')}>
                                <ArrowForwardIosIcon />
                            </Link>
                            :
                            <Box className={(currPage === pageCount ? 'arrow disabled ' : 'arrow')}>
                                <ArrowForwardIosIcon />
                            </Box>
                    }
                </ListItem>
            </List>
        </Grid>
    );
}

export default Pagination;