import { Box } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import useTranslation from "../../hooks/useTranslation";
import cssVars from "../../styles/vars.module.scss";

const PlpEmpty = () => {
    const { t } = useTranslation();

    return (
        <Box component="section" sx={{ fontSize: "24px", fontWeight: 500, color: cssVars.extraDarkGray }} display="flex" alignItems="center" justifyContent="center" height={400}>
            <Box>
                <Box textAlign="center" mb={2}>
                    <InfoIcon fontSize="large" sx={{ fill: cssVars.extraDarkGray, fontSize: "100px" }} />
                </Box>
                <Box>
                    {t(`There are no products matching this selection`)}
                </Box>
            </Box>
        </Box>
    );
}

export default PlpEmpty;