import styles from './style.module.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { useRouter } from "next/router"
import parseSearchParams, { strEncode } from '../../helpers/SearchParams';
import { Box, Button } from '@mui/material';
import useTranslation from '../../hooks/useTranslation';

const DropdownFilter = ({ filter }) => {
    const { t } = useTranslation((state) => state.translate)
    const innerLabel = t('Apply')
    const { attribute_code: id, label, options } = filter
    const [searchParams, setSearchParams] = useState({})
    const router = useRouter()
    useEffect(() => {
        setSearchParams(parseSearchParams(router))
    }, [router])

    const getFilters = () => {
        if (searchParams?.filters && searchParams?.filters[id]) {
            return searchParams.filters[id]
        }
        else {
            return []
        }
    }

    const [filters, setFilters] = useState([])
    const [clearFilters, setClearFilters] = useState(false)

    useEffect(() => {
        setFilters(getFilters())
    }, [getFilters().length])


    useEffect(() => {
        if (clearFilters) {
            doFilters()
            setClearFilters(false)
        }
    }, [filters])


    useEffect(() => {
        window.addEventListener('click', function (e) {
            if (document.querySelector('.dropdown-filter #dropdown' + id) && !document.querySelector('.dropdown-filter #dropdown' + id).parentElement.contains(e.target)) {
                setFilters(getFilters().sort())
                document.querySelector(`#${id} button.label svg`).classList.remove(styles['rotated-icon'])
                document.querySelector('.dropdown-filter #dropdown' + id).classList.remove(styles.show)
            }
        });
        return () => { }
    }, [])

    const open = (e, id) => {
        if (document.getElementById('dropdown' + id).classList.contains(styles.show)) {
            document.querySelector(`#${id} button.label svg`).classList.remove(styles['rotated-icon'])
            document.getElementById('dropdown' + id).classList.remove(styles.show)
        }
        else {
            document.querySelector(`#${id} button.label svg`).classList.add(styles['rotated-icon'])
            document.getElementById('dropdown' + id).classList.add(styles.show)
        }
    }

    const handleFilter = (e, value) => {
        const f = filters
        if (e.target.checked) {
            setFilters([...filters, value].sort())
        }
        else {
            setFilters(f.filter(f => f !== value))
        }
    }

    const doFilters = () => {
        let s = searchParams
        if (!s.filters) s.filters = {}
        s.filters[id] = filters

        if (s.filters[id].length === 0) {
            delete s.filters[id]
            if (Object.keys(s.filters).length === 0)
                delete s.filters
        }
        delete s.sort
        delete s.page
        var localParam = Object.keys(s).length > 0 ? "/" + strEncode(JSON.stringify(s)) : ""
        if (Object.keys(parseSearchParams(router)).length === 0)
            router.push(router.asPath + localParam)
        else
            router.push(router.asPath.split('/').slice(0, -1).join('/') + localParam)
        document.querySelector(`#${id} button.label svg`).classList.remove(styles['rotated-icon'])
        document.getElementById('dropdown' + id).classList.remove(styles.show)
    }


    const delFilters = () => {
        setClearFilters(true)
        setFilters([])
    }

    return (
        <Box sx={{ display: { xs: "none", lg: "block" } }} className={"dropdown-filter " + styles['dropdown-filter']} id={id}>
            <Button className={"label " + styles.label + " " + (getFilters().length > 0 ? styles.selected : '')} onClick={(e) => { open(e, id) }}>{label} <KeyboardArrowDownIcon fontSize="medium" />
                <Box className={"d-none " + styles.clear} onClick={delFilters}>
                    <CloseIcon fontSize="medium" />
                </Box>
            </Button>
            <Box className={"dropdown " + styles.dropdown} id={'dropdown' + id}>
                <Box component="ul" sx={{ p: 0 }}>
                    {options.map(option =>
                        <Box component="li" className='noselect' sx={{ display: "flex", alignItems: "center" }} key={`${option.label}_${option.value}`}>
                            <Box component="span" >
                                <input type="checkbox"
                                    id={id + "_" + option.value}
                                    style={{ cursor: "pointer" }}
                                    name={option.label + "-checkbox"}
                                    value={option.value}
                                    checked={filters?.indexOf(option.value) > -1}
                                    onChange={(e) => { handleFilter(e, option.value) }}
                                />
                            </Box>
                            <Box sx={{ mr: 1, fontWeight: 500 }} onClick={(e) => { e.target.parentElement.getElementsByTagName('input')[0].click() }}>
                                {(option.value === option.label && (option.label === '0' || option.label === '1'))
                                    ?
                                    (option.label !== '0' ? t('Yes') : t('No'))
                                    :
                                    option.label
                                }
                            </Box>
                            <Box sx={{ mt: 0.25 }} className={styles.count}>({option.count})</Box>
                        </Box>
                    )}
                </Box>
                <Box sx={{ p: "10px", pt: 0 }}>
                    <Button sx={{ mt: 1.5 }} className={styles["submit-filter-btn"]} onClick={() => { doFilters() }}>{innerLabel}</Button>
                </Box>
            </Box>
        </Box>
    );
}

export default DropdownFilter;