import { Box } from "@mui/material";

import ProductCollection from "../product-collection";
import SpecialFilterTabs from "./specialFilterTabs";
import PLPBreadCrumb from "./plpBreadBrumb";
import PLPSubCategories from "./plpSubCategories";
import PLPCmsBlock from "./plpCMSBlock";
import PLPFiles from "./plpFiles";
import PLPFiltersSort from "./plpFiltersSort";
import PlpEmpty from "./plpEmpty";
import parseSearchParams from "../../helpers/SearchParams";
import { useRouter } from "next/router";
import { useGlobalStore } from "../../stores/globalStore";

const PLP = ({ data, catData, filtersData, sortData, cmsBlock, storeConfig, forSearch = false, files, campaign, isCalibration = false }) => {
    const searchParams = parseSearchParams(useRouter())

    return (
        <Box className="container">
            <PLPBreadCrumb catData={catData} />
            {
                !isCalibration
                    ?
                    <>
                        <PLPSubCategories catData={catData} />
                        <PLPCmsBlock cmsBlock={cmsBlock} />
                        <PLPFiles files={files} />
                        {
                            !forSearch
                                ?
                                <SpecialFilterTabs filters={filtersData.products.aggregations} total_product_count={filtersData.products.total_count} />
                                :
                                null
                        }
                    </>
                    :
                    null
            }
            <Box component="section">
                {
                    (data?.products?.items?.length > 0 || (searchParams && Object.keys(searchParams).length > 0))
                        ?
                        <PLPFiltersSort filtersData={filtersData} sortData={sortData} forSearch={forSearch} isCalibration={isCalibration} />
                        :
                        null
                }
                {
                    (isCalibration ? (data?.products?.items?.length > 0) : data?.products?.items?.length > 0)
                        ?
                        <ProductCollection data={data} catData={catData} storeConfig={storeConfig} forSearch={forSearch} campaign={campaign} isCalibration={isCalibration} />
                        :
                        <PlpEmpty />
                }
            </Box>
        </Box>
    )
}

export default PLP;