import { Box } from "@mui/material";
import Breadcrumb from "../breadcrumb";

const PLPBreadCrumb = ({ catData }) => {
    return (
        catData
            ?
            <Box component="section" mb={{ xs: 1, md: 2 }}>
                <Breadcrumb breadcrumbs={catData.categories.items[0].breadcrumbs} activeName={catData.categories.items[0].name} />
            </Box>
            :
            null
    );
}

export default PLPBreadCrumb;