import Pagination from "../pagination/pagination";
import ProductList from "../product-list/productList";
import { memo } from "react";

const PaginatedProductList = ({ data, catData, campaign, isCalibration }) => {
    return (
        <>
            <ProductList products={data.products.items} category_path={catData?.categories.items[0].url_path} campaign={campaign} isCalibration={isCalibration} />
            <Pagination pageCount={data.products.page_info.total_pages} btnCount={4} currPage={data.products.page_info.current_page} />
        </>
    );
}

export default memo(PaginatedProductList);