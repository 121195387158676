import { Box } from "@mui/material";
import Link from "next/link";
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import cssVars from "../../styles/vars.module.scss";
import useTranslation from "../../hooks/useTranslation";


const ProductCategoryDownloads = ({ files, variant = "col" }) => {

    const fileSize = (value) => {
        const units = ['bytes', 'KB', 'MB']
        let l = 0
        let n = parseInt(value, 10) || 0

        while (n >= 1024 && ++l) {
            n = n / 1024
        }
        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l])
    }

    const { t } = useTranslation()

    return (
        <Box sx={{ fontSize: 14, mt: 5, display: variant === "row" ? "flex" : "block", alignItems: "center" }}>
            {files && files.length > 0
                ?
                files.map(file =>
                    <Link key={file.url} href={file.url} target="_blank" style={{ display: "flex", alignItems: "center", marginTop: variant === "row" ? 0 : "16px", marginRight: variant === "row" ? "24px" : 0, cursor: "pointer", width: "fit-content" }}>
                        <Box sx={{ borderRadius: "50%", bgcolor: cssVars.extraLightGray, mr: 1.5, py: 0.85, px: 1 }}>
                            <SaveAltIcon />
                        </Box>
                        <Box sx={{ fontWeight: 500 }}>
                            {file.title}
                        </Box>
                        <Box sx={{ color: cssVars.extraDarkGray, ml: 1.5, fontWeight: 600 }}>{"(" + t("Size") + ": " + fileSize(file.size) + ")"}</Box>
                    </Link>
                )
                :
                null
            }
        </Box>
    );
}

export default ProductCategoryDownloads;