import { Box } from "@mui/material";
import { CmsPageParser } from '../cms-parser'

const PLPCmsBlock = ({ cmsBlock }) => {
    return (
        cmsBlock
            ?
            <Box component="section" my={5}>
                <CmsPageParser id={cmsBlock.id}>
                    {cmsBlock.content}
                </CmsPageParser>
            </Box>
            :
            null
    );
}

export default PLPCmsBlock;