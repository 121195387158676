import { Box, Grid } from "@mui/material";
import ProductCard from "../product-card";
import CampaignCard from "../widgets/promoPro/campaignCard";
import { useState } from "react";
import { useEffect } from "react";

const ProductList = ({ products, category_path, pageSize = -1, current_page = -1, campaign, isCalibration }) => {
    const [campaignIndex, setCampaignIndex] = useState(null)

    useEffect(() => {
        setCampaignIndex((Math.floor(Math.random() * (products.length - 1)) + 1))
    }, [products])

    return (
        <Box className="products-list" mt={2} mb={5}>
            <Grid container rowSpacing={{ xs: "45px", sm: "30px" }} columnSpacing={{ xs: "16px", sm: "30px" }} columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                {
                    products.map((product, i) =>
                        <>
                            <Grid item xs={1} key={product.sku}>
                                <ProductCard product={product} category_path={category_path} index={i} pageSize={pageSize} current_page={current_page} isCalibration={isCalibration} />
                            </Grid>
                            {
                                (i + 1) === campaignIndex && campaign
                                    ?
                                    <Grid item xs={1} key={campaign.id + campaignIndex}>
                                        <CampaignCard item={campaign} />
                                    </Grid>
                                    :
                                    null
                            }
                        </>
                    )
                }
            </Grid>
        </Box>
    );
}

export default ProductList;