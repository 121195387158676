import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useRouter } from 'next/router';
import parseSearchParams, { strEncode } from '../../helpers/SearchParams';
import cssVars from '../../styles/vars.module.scss'
import WhatshotIcon from "@mui/icons-material/Whatshot";
import FlashOnIcon from '@mui/icons-material/FlashOn';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const getSpecialFilters = (filters) => {
    const hasLowPriceResults = filters?.filter(filter => filter.attribute_code === 'has_low_price')?.at(0)?.options?.filter(option => option.value === '1' || option.value === true)?.at(0)?.count > 0;
    const hasClearanceResults = filters?.filter(filter => filter.attribute_code === 'clearance')?.at(0)?.options?.filter(option => option.value === '1' || option.value === true)?.at(0)?.count > 0;
    const hasSaleResults = filters?.filter(filter => filter.attribute_code === 'on_sale')?.at(0)?.options?.filter(option => option.value === '1' || option.value === true)?.at(0)?.count > 0;

    const specialFilters = []

    if (hasSaleResults) specialFilters.push(filters?.filter(filter => filter.attribute_code === 'on_sale')?.at(0))
    if (hasClearanceResults) specialFilters.push(filters?.filter(filter => filter.attribute_code === 'clearance')?.at(0))
    if (hasLowPriceResults) specialFilters.push(filters?.filter(filter => filter.attribute_code === 'has_low_price')?.at(0))

    return specialFilters;
}

const getSelectedFilter = (filters) => {
    if (filters && Object.keys(filters)?.length > 0) {
        const selectedFilter = Object.keys(filters).filter(key => key === 'has_low_price' || key === 'clearance' || key === 'on_sale')?.at(0);
        return selectedFilter;
    }
    else {
        return 'all';
    }
}


const getYesOptionCount = (filter) => {
    return filter.options.filter(option => option.value === '1' || option.value === true)?.at(0)?.count;
}

const getColor = (code) => {
    switch (code) {
        case 'has_low_price':
            return cssVars.lightBlue
        case 'clearance':
            return cssVars.extraDarkGray
        case 'on_sale':
            return cssVars.red
        default:
            return cssVars.extraDarkGray
    }
}

const getIcon = (code) => {
    switch (code) {
        case 'has_low_price':
            return <TrendingDownIcon sx={{ width: 22, height: 22 }} />
        case 'clearance':
            return <FlashOnIcon sx={{ width: 22, height: 22 }} />
        case 'on_sale':
            return <WhatshotIcon sx={{ width: 22, height: 22 }} />
        default:
            return null
    }
}


const SpecialFilterTabs = ({ filters, total_product_count }) => {
    const router = useRouter();
    const specialFilters = getSpecialFilters(filters);
    const selectedFilter = getSelectedFilter(parseSearchParams(router)?.filters);

    const handleTabChange = (_, newValue) => {
        doFilter(newValue, { value: '1' })
    };

    const doFilter = (attribute_code, option) => {
        const searchParams = parseSearchParams(router)
        const url = Object.keys(searchParams).length > 0 ? router.asPath.split('/').slice(0, -1).join('/') : router.asPath
        if (attribute_code === 'all') {
            router.push(url)
        }
        else {
            router.push(`${url}/${strEncode(JSON.stringify({ filters: { [attribute_code]: option.value } }))}`)
        }
    }

    return (
        specialFilters.length > 0
            ?
            <Box component="section" my={5} >
                <Tabs value={selectedFilter} onChange={handleTabChange} aria-label="special filter tabs" sx={{ borderBottom: { xs: 'none', md: `1px solid ${cssVars.lightGray}` }, "& [role=tablist]": { flexWrap: "wrap", justifyContent: { xs: "center", md: "start" } }, "& button": { fontSize: { xs: "14px", md: "16px" } } }} indicatorColor='transparent'>
                    <Tab key={'all'} label={
                        <Box sx={{ display: "inline-flex" }}>
                            <Box mr={1}>{'All'}</Box>
                            <Box>({total_product_count})</Box>
                        </Box>
                    } value={'all'} sx={{
                        color: {
                            xs: selectedFilter === 'all' ? `#fff !important` : getColor('all'),
                            md: `${getColor('all')} !important`
                        },
                        display: { xs: "block", md: "inline-block" },
                        border: { xs: `1px solid ${getColor("all")} !important`, md: 'none !important' },
                        borderBottom: { md: selectedFilter === 'all' ? `2px solid ${getColor('all')} !important` : "none" },
                        borderRadius: { xs: "6px", md: 'initial' },
                        mr: { xs: 2, md: 0 },
                        mt: { xs: 2, md: 0 },
                        bgcolor: { xs: selectedFilter === "all" ? getColor("all") : 'transparent', md: "transparent" }

                    }} />
                    {specialFilters.map((filter) => (
                        <Tab key={filter.attribute_code} label={
                            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                                <Box mr={1}>{getIcon(filter.attribute_code)}</Box>
                                <Box mr={1}>{filter.label}</Box>
                                <Box>({getYesOptionCount(filter)})</Box>
                            </Box>
                        } value={filter.attribute_code} sx={{
                            color: {
                                xs: selectedFilter === filter.attribute_code ? `#fff !important` : getColor(filter.attribute_code),
                                md: `${getColor(filter.attribute_code)} !important`
                            },
                            display: { xs: "block", md: "inline-block" },
                            border: { xs: `1px solid ${getColor(filter.attribute_code)} !important`, md: 'none !important' },
                            borderBottom: { md: selectedFilter === filter.attribute_code ? `2px solid ${getColor(filter.attribute_code)} !important` : "none" },
                            borderRadius: { xs: "6px", md: 'initial' },
                            mr: { xs: 2, md: 0 },
                            mt: { xs: 2, md: 0 },
                            bgcolor: { xs: selectedFilter === filter.attribute_code ? getColor(filter.attribute_code) : 'transparent', md: "transparent" }
                        }} />
                    ))}
                </Tabs>
            </Box >
            :
            null
    );
};

export default SpecialFilterTabs;