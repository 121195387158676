import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControlLabel, Grid, IconButton, List, ListItem } from "@mui/material";
import Drawer from "../drawer/drawer";
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PriceFilter from "../price-filter/priceFilter";
import { useRouter } from "next/router";
import parseSearchParams, { strEncode } from "../../helpers/SearchParams";
import useTranslation from "../../hooks/useTranslation";
import cssVars from "../../styles/vars.module.scss";
import logo from '../../media/images/mobile_logo.png'
import CancelIcon from '@mui/icons-material/Cancel';

import Image from "next/image";

const FiltersDrawer = ({ filters, openFiltersDrawer, setOpenFiltersDrawer }) => {

    const router = useRouter()
    const searchParams = parseSearchParams(router)

    const distictArr = (arr) => arr.filter((item, index, arr) => arr.indexOf(item) === index)


    const doFilter = (e, attribute_code, option) => {
        var s = { ...searchParams }
        delete s.page
        if (!s.filters) {
            s.filters = {}
        }
        if (e.target.checked) {
            if (!s.filters[attribute_code]) {
                s.filters[attribute_code] = []
            }

            s.filters[attribute_code] = distictArr([...s.filters[attribute_code], option.value]).sort()

            s.filters = Object.fromEntries(Object.entries(s.filters).sort())
            s = Object.fromEntries(Object.entries(s).sort())
        }
        else {
            if (option) {
                s.filters[attribute_code] = s.filters[attribute_code].filter(value => value !== option.value)
                if (s.filters[attribute_code].length === 0) {
                    delete s.filters[attribute_code]
                }
            }
            else {
                delete s.filters[attribute_code]
            }
            if (Object.keys(s.filters).length === 0) {
                delete s.filters
            }

        }

        if (Object.keys(s).length === 0) {
            router.push(`${router.asPath.endsWith('.html') ? router.asPath : router.asPath.split('/').slice(0, -1).join('/')}`)
        }
        else {
            router.push(`${router.asPath.endsWith('.html') ? router.asPath : router.asPath.split('/').slice(0, -1).join('/')}/${strEncode(JSON.stringify(s))}`)
        }

    }


    const getFilterLabel = (code, value) => {
        for (const f of filters) {
            if (f.attribute_code === code) {
                for (const option of f.options) {
                    if (option.value === value) {
                        return option.label
                    }
                }
            }
        }
    }

    const isChecked = (attribute_code, optionValue) => {
        return searchParams.filters ? searchParams.filters[attribute_code]?.includes(optionValue) : false
    }

    const { t } = useTranslation()

    const filteredOptions = (aggregations) => {
        console.log(aggregations);
        return aggregations.map((aggregation) => {
            const filteredOptions = aggregation?.options?.filter(
                (option) => option?.value !== option?.label
            )
            if (filteredOptions?.length === 0) {
                return null
            }
            return {
                ...aggregation,
                options: filteredOptions,
            }
        }).filter(Boolean)
    }
    console.log(searchParams.filters);

    const clearAll = () => {
        if (Object.keys(parseSearchParams(router)).length > 0)
            router.push(router.asPath.split('/').slice(0, -1).join('/'))
    }

    return (
        <Drawer sx={{ display: { xs: "block", md: "none" } }} open={openFiltersDrawer} setOpen={setOpenFiltersDrawer} anchor="right" swipeable={false}>
            <Box>
                <Grid mx="-24px" px="24px" paddingBottom="15px" borderBottom={`1px solid #DFE1E2`} minWidth={320} display="flex" alignItems="center" justifyContent="space-between">
                    <Box></Box>
                    <Image src={logo} alt="mobile logo" style={{ transform: "translateX(50%)" }} />
                    <IconButton onClick={() => { setOpenFiltersDrawer(false) }} sx={{ px: 0 }}>
                        <CancelIcon htmlColor='#0072BA' fontSize="large" />
                    </IconButton>
                </Grid>
                {
                    searchParams.filters && Object.keys(searchParams.filters)?.length > 0
                        ?
                        <Box py="15px" mx="-24px" px="24px" borderBottom={`1px solid #DFE1E2`} minWidth={320}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                                <Box fontSize={15} fontWeight={600}>{t("Selected Filters")}</Box>
                                <Box fontSize={13} fontWeight={600} sx={{ textDecoration: "underline" }} onClick={() => { clearAll() }}>{t("Clear Filters")}</Box>
                            </Box>
                            <Grid display="flex" alignItems="center" flexWrap="wrap">
                                {
                                    Object.keys(searchParams.filters).map((fkey) =>
                                        fkey === 'price'
                                            ?
                                            <Button key={fkey} className="fs-12" sx={{ borderRadius: '25px', textTransform: "none", mr: 1, my: 0.5, p: "6px 12px", fontSize: 14, height: "fit-content" }}
                                                variant="contained" color="primary" endIcon={<CloseIcon fontSize="small" sx={{ fontSize: "16px" }} />}
                                                onClick={(e) => { doFilter({ target: { checked: false } }, fkey) }}>
                                                {searchParams.filters[fkey].join(' - ')}
                                            </Button>
                                            :
                                            searchParams.filters[fkey] && Array.isArray(searchParams.filters[fkey]) && searchParams.filters[fkey].map(value =>
                                                <Button key={`${fkey}-${value}`} className="fs-12" sx={{ borderRadius: '25px', textTransform: "none", mr: 1, my: 0.5, p: "6px 12px", fontSize: 14, height: "fit-content" }}
                                                    variant="contained" color="primary" endIcon={<CloseIcon fontSize="small" sx={{ fontSize: "16px" }} />}
                                                    onClick={(e) => { doFilter({ target: { checked: false } }, fkey, { value }) }}>
                                                    {getFilterLabel(fkey, value)}
                                                </Button>
                                            )
                                    )
                                }
                            </Grid>
                        </Box>
                        :
                        null
                }
                <Box py="15px" minWidth={320}>
                    <Box fontSize={15} fontWeight={600}>{t("Filter")}</Box>
                    {filters && filteredOptions(filters).map((filter, i) =>
                        <Accordion disableGutters disablePadding key={filter.attribute_code} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon color={cssVars.black} />}
                            >
                                <Box fontSize={15} fontWeight={500}>{filter.label}</Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                {filter.attribute_code === "price" ?
                                    <PriceFilter id={filter.attribute_code} count={filter.options.length} options={filter.options} />
                                    :
                                    <List disablePadding id={`fg-${i}`} sx={{
                                        maxHeight: 300, overflowY: "auto",
                                        "&::-webkit-scrollbar": { width: 8 },
                                        "&::-webkit-scrollbar-track": { background: '#F6F6F6' },
                                        "&::-webkit-scrollbar-thumb": { background: '#ECECEC', borderRadius: 20 },
                                    }}>
                                        {
                                            [...filter.options].sort((a, b) => a.label.localeCompare(b.label)).filter(f => !(f.label === "0" && f.value === "0")).map((option, j) =>
                                                <ListItem sx={{ "& label span": { fontSize: "15px !important" } }} disablePadding key={`chk_${filter.attribute_code}_${option.value}`} mb={2}>
                                                    <FormControlLabel
                                                        id={`chk_${filter.attribute_code}_${option.value}`}
                                                        label={option.label}
                                                        control={
                                                            <Checkbox
                                                                checked={isChecked(filter.attribute_code, option.value)}
                                                                onChange={(e) => { doFilter(e, filter.attribute_code, option) }} />
                                                        } />
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                }

                            </AccordionDetails>
                        </Accordion>
                    )}
                </Box>
            </Box>
        </Drawer>
    );
}

export default FiltersDrawer;